<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Dashboard Statistics</h3>
      </div>
      <div class="stats-list sidemenu">
        <div
          v-for="(stat, index) in dashboardStatics"
          :key="index"
          class="stat-item"
        >
          <div class="single-stat">
            <el-row type="flex">
              <el-col>
                <label for="allDocuments">{{ stat.title }}</label>
              </el-col>
              <el-col>
                <el-switch
                  v-model="stat.status"
                  id="allDocuments"
                  active-text="ON"
                  inactive-text="OFF"
                  @change="saveDashboardStats"
                >
                </el-switch>
              </el-col>
            </el-row>
          </div>
          <el-divider class="stat-divider my-1"></el-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Dashboard-Stats",
  async mounted() {
    this.loading = true;
    await this.fetchUserdashboardStatics();
    await this.setData();
    this.loading = false;
  },
  computed: {
    ...mapGetters("settings", [
      "getUserDashboardStastics",
      "getUpdateUserDashboardStaticsStatus",
    ]),
  },
  methods: {
    async fetchUserdashboardStatics() {
      await this.$store.dispatch("settings/fetchUserDashboardStatics");
    },
    setData() {
      if (this.getUserDashboardStastics) {
        this.dashboardStatics = this.getUserDashboardStastics.data;
      }
    },
    async saveDashboardStats() {
      if (this.dashboardStatics) {
        let dashboardStatics = {};
        dashboardStatics.dashboard_stats = this.dashboardStatics;
        await this.$store.dispatch(
          "settings/updateUserDashboardStatics",
          dashboardStatics
        );
        if (this.getUpdateUserDashboardStaticsStatus) {
          this.$notify.success({
            title: "Success",
            message: "Dashboard Stats settings updated successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in updating the Dashboard Status settings",
          });
        }
      }
    },
  },
  data() {
    return {
      documentStatics: {},
      allDocuments: true,
      allTemplates: true,
      allApproval: true,
      sentDocs: true,
      viewedDocs: true,
      completedDocs: false,
      loading: false,
      dashboardStatics: [],
    };
  },
  beforeDestroy() {
    this.$store.commit(
    "settings/setUserDashboardStastics", null,{root:true})
    this.$store.commit("settings/setUpdateUserDashboardStaticsStatus", null,
     {
      root: true,
    }
    );
  },
};
</script>

<style lang="scss" scoped>
.sidemenu{
  height: 580px;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width:thin;
  border-top:1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  
}
.sidemenu::-webkit-scrollbar {
   width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9); 
}
.sidemenu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5; 
    background-color: #F5F5F5;

}
.stats-list {
  .single-stat {
    padding: 1em 1.5em;
  }
  .stat-item:last-child {
    .stat-divider {
      display: none;
    }
  }
}
</style>